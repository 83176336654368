<template>
  <div class="col-12 col-md-6 text-left welcomeText">
    <h1>Reset Password anda</h1>
    <h5>
      Masukkan username dan nomor telepon yang anda gunakan saat melakukan
      pendaftaran
    </h5>
    <h6 class="text-white">
      Pastikan username dan No telepon yang anda masukkan benar untuk
      mendapatkan kode OTP
    </h6>
    <form @submit.prevent="sendResetCode">
      <div class="form-group mt-3">
        <label class="text-white" for="exampleFormControlSelect1"
          >Username</label
        >
        <input
          type=""
          class="form-control"
          aria-describedby="emailHelp"
          placeholder="username"
          required
          oninvalid="this.setCustomValidity('Silahkan isi username kamu')"
          v-model="userName"
          pattern="^[_.@a-z0-9-]*$"
        />
      </div>
      <div class="form-group mt-3">
        <label class="text-white" for="exampleFormControlSelect1"
          >Telepon</label
        >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">+62</span>
          </div>
          <input
            type="number"
            class="form-control"
            id="telfon"
            aria-describedby=""
            placeholder="nomor telepon"
            required
            v-model="telepon"
          />
        </div>
      </div>
      <button
        type="submit"
        @click="sendWithTutorrial"
        class="btn btn-light mt-1 btn-sign-in"
        style="background-color: rgba(255, 255, 255, 0.5); color: white"
      >
        <template v-if="loading">
          <div
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <span class="span-loading">Loading</span>
        </template>
        <h5 class="mb-0" v-if="!loading">Kirim OTP</h5>
      </button>
    </form>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      userName: "",
      telepon: "",
      loading: false,
    };
  },
  mounted() {},
  methods: {
    sendWithTutorrial() {
      if (this.$store.state.isTutorial) {
        const confirmPassword = {
          name: "Diana",
          telepon: "813328811",
        };
        sessionStorage.setItem("resetPas", JSON.stringify(confirmPassword));
        this.$router.push({ name: "ConfirmResetPassword" });
        return;
      }
    },
    sendResetCode() {
      if (this.$store.state.isTutorial) {
        const confirmPassword = {
          name: "Diana",
          telepon: "813328811",
        };
        sessionStorage.setItem("resetPas", JSON.stringify(confirmPassword));
        this.$router.push({ name: "ConfirmResetPassword" });
        return;
      }
      if (this.telepon.length <= 6) {
        this.$toast.error("No telepon anda masih kurang");
        document.getElementById("telfon").focus();
        return;
      }
      this.loading = true;
      const telepon =
      this.telepon[0] == "8" ? `62${this.telepon}` : this.telepon;
      axios
        .post(`/api/send-reset-code`, {
          primaryPhoneNomor: telepon,
          username: this.userName,
        })
        .then(() => {
          const confirmPassword = {
            name: this.userName,
            telepon,
          };
          this.$toast.success("Kode OTP sudah dikirim ke nomor handphone anda");
          sessionStorage.setItem("resetPas", JSON.stringify(confirmPassword));
          this.$router.push({ name: "ConfirmResetPassword" });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>